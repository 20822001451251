export default [
  {
    groups: [
      {
        title: 'THÔNG TIN',
        attributes: [
          {
            prop: 'name',
            label: 'Họ tên',
            type: 'text'
          },
          {
            prop: 'email',
            label: 'Email',
            type: 'text'
          },
          {
            prop: 'phone',
            label: 'Số điện thoại',
            type: 'text'
          }
        ]
      }
    ]
  },
  {
    groups: [
      {
        title: 'PHÂN QUYỀN',
        attributes: [
          {
            prop: 'objRoles',
            label: 'Phân quyền',
            type: 'role'
          }
        ]
      }
    ]
  }
]
