<template lang="html">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12">
      <form-card
        v-for="(group, index) of centerGroups"
        :group="group"
        :key="index"
        v-model="form"
      ></form-card>
    </div>
    <div class="col-lg-4 col-md-4 col-sm-12">
      <form-card
        v-for="(group, index) of sideGroups"
        hasTop="true"
        :key="index"
        :group="group"
        v-model="form"
      >
      </form-card>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Lưu lại</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import FormCard from 'src/components/UIComponents/FormCard.vue'
import { Message, Input, Button } from 'element-ui';
import dataFrom from './user-form'
import { mapState } from 'vuex'

let _form = {}, firstGroups, secondGroups;

export default {
  components: {
    FormCard,
    ElInput: Input,
    ElButton: Button,
  },
  beforeCreate() {

    firstGroups = dataFrom[0].groups;
    secondGroups = dataFrom[1].groups;

    firstGroups.forEach(group => {
      group.attributes.forEach(attr => {
        if (attr.multiple && _form[attr.prop]) {
          _form[attr.prop] = [];
        } else {
          _form[attr.prop] = '';
        }
        attr.value = _form[attr.prop];
      })
    })
    secondGroups.forEach(group => {
      group.attributes.forEach(attr => {
        if (attr.multiple && _form[attr.prop]) {
          _form[attr.prop] = [];
        } else {
          _form[attr.prop] = '';
        }
        attr.value = _form[attr.prop];
      })
    })

    window.AP = this;
  },
  data () {
    return {
      form: _form,
      centerGroups: firstGroups,
      sideGroups: secondGroups
    }
  },
  mounted() {
    this.$store.dispatch('setPageTitle', 'Tạo tài khoản quản trị');
    this.$store.dispatch('setCurrentActions', [{
      label: 'Lưu lại',
      action: 'storeUser'
    }])
  },
  methods: {
    cancel() {
      this.$router.push({name: 'AllUsers'});
    },
    save() {
      this.$store.dispatch('storeUser', this.form);
    }
  },
  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
  }
}
</script>
